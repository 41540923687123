<template>
    <div class="user">
        <div class="dept box-shadow">
            <el-tree :data="treeselectOptions" default-expand-all :expand-on-click-node="false" @node-click="nodeClick" ref="deptTree">
                <div class="custom-tree-node" :class="{'active':activeDeptId==data.id}" slot-scope="{ node, data }">{{  data.deptName  }}</div>
            </el-tree>
        </div>
        <div class="mainList">
            <div class="mainList_content box-shadow">
                <div class="mainList_content_operation">
                    <div class="mainList_content_operation-l">
                        <el-button size="small" icon="el-icon-plus" v-if="permission(['USER_ADD'])" @click="add">新增</el-button>
                        <el-button type="danger" icon="el-icon-delete" v-if="permission(['USER_DEL'])" size="small" @click="del()">批量删除</el-button>
                    </div>
                    <div class="mainList_content_operation-c">
                        <div class="item">
                            <div class="label">姓名</div>
                            <el-input placeholder="请输入" size="small" clearable v-model="parameter.condition.name" />
                        </div>
                        <el-button class="item" type="primary" size="small" icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                    </div>

                    <div class="mainList_content_operation-r">
                        <div class="item icon iconfont iconrefresh" @click="getData"></div>
                        <el-popover class="item" placement="bottom" v-model="tableSettingVisible" popper-class="tableColumnSettingPopover">
                            <TableColumnSetting v-model="tableColumnData"></TableColumnSetting>
                            <div slot="reference" class="item icon iconfont iconliebiaoshezhi"></div>
                        </el-popover>
                    </div>


                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" :stripe="elTable.stripe" :border="elTable.border" :data="tableData" tooltip-effect="dark" height="string" @sort-change="sortChange" v-loading="loading" header-cell-class-name="meterHeader">

                        <el-table-column type="selection" align="center" width="55" fixed="left"></el-table-column>
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>

                        <el-table-column v-for="n in tableColumnData" v-if="n.isShow" :fixed="n.fixed" align="center" :prop="n.prop" :label="n.label" :sortable="n.sortable">
                            <template slot-scope="scope">
                                <template v-if="n.filter">
                                    {{  n.filter(scope.row[n.prop])  }}
                                </template>
                                <template v-else>
                                    {{  scope.row[n.prop]  }}
                                </template>
                            </template>
                        </el-table-column>


                        <el-table-column label="操作" fixed="right"  align="center" width="200" v-if="permission(['USER_EDIT','USER_DEL','USER_ROLE','USER_TRANSFER'])">
                            <template slot-scope="scope">
                                <el-button class="edit" type="text" v-if="permission(['USER_EDIT'])" @click="edit(scope.row)">编辑</el-button>
                                <el-button type="text" v-if="permission(['USER_ROLE'])" @click="roleAssociation(scope.row)">角色关联</el-button>
                                <el-button class="del" type="text" v-if="permission(['USER_DEL'])" @click="del(scope)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @current-change="pageChangeHandler"
                        @size-change="handleSizeChange"
                        :current-page="parameter.pageNumber"
                        :page-size="parameter.pageSize"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Edit :refresh="refresh" :isShow.sync="showEditDialog" :options="options" :treeselectOptions="treeselectOptions" :deptId="parameter.condition.deptId"></Edit>
        <RoleAssociation :refresh="refresh" :isShow.sync="showRoleAssociation" :options="roleAssociationOptions"></RoleAssociation>
    </div>
</template>
<script>
import mixinsList from '@/mixins/list'
import {initTreeData} from "@/util/common";
export default {
    components: {
        Edit: () => import("./Edit.vue"),
        RoleAssociation: () => import("./RoleAssociation.vue"),
        TableColumnSetting: () => import("@/components/TableColumnSetting.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                findPage:this.$api['admin/user'].findPage,
                del:this.$api['admin/user'].del,
            },
            activeDeptId:null,
            showRoleAssociation: false,
            roleAssociationOptions:{},
            options: {rePassword:''},
            tableColumnData:[
                {prop:'name',label:'姓名',isShow:true,sortable:true,fixed:false,sortable:'custom',},
                {prop:'userName',label:'用户名',isShow:true,sortable:true,fixed:false,sortable:false,},
            ],
            treeselectOptions:[],
        };
    },
    watch: {
        activeDeptId: {
            deep: true,//对象深度监测
            handler: function (id) {
                var arr = [id];
                function getId(data){
                    data.some(r=>{
                        arr.push(r.id);
                        if(r.children){
                            getId(r.children)
                        }
                    })
                }
                this.treeselectOptions.some(r=>{
                    if(r.id==id&&r.children){
                        getId(r.children)
                    }
                });
                this.parameter.condition.deptIds = arr;
                console.log(arr)
                this.pageChangeHandler(1);
            }
        },
    },
    created() {
        this.$api['admin/dept'].findByList().then(res => {
            this.treeselectOptions = initTreeData(res.data);
        }).catch(err => {
            console.log(err);
        });
        this.getData();
    },
    methods: {
        nodeClick(r){
            this.activeDeptId = r.id;
        },
        add() {
            this.options = {
                rePassword:'',
                deptId:this.activeDeptId,
            };
            this.showEditDialog = true;
        },
        refresh(){
            this.showEditDialog = false;
            this.showRoleAssociation = false;
            this.getData();
        },
        roleAssociation(row) {
            this.roleAssociationOptions = row;
            this.showRoleAssociation = true;
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.user {
    padding-left:320px;
    .dept {
        position:absolute;
        top:20px;
        bottom:20px;
        left:20px;
        width:300px;
        background: #fff;
        padding: 15px;
        border-radius: 5px;
        overflow:auto;
        font-size:14px;
        /deep/.el-tree {
            .el-tree-node__content {
                background:none;
                &:hover {color:$themeColor;}
            }
            .custom-tree-node {
                &.active {
                    color:$themeColor;
                }
            }
        }
    }
}
</style>
